import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow } from "swiper";


import "./Hero.scss";

// swiper's css files.....
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

// Images...
import DownArrowIcon from "../../assets/images/downArrowIcon.png";
import subscribeHoverIcon from "../../assets/images/subscribeHoverIcon.png";
import SliderImg1 from "../../assets/images/sliderImg2.1.png";
import SliderImg2 from "../../assets/images/sliderImg2.2.png";
import SliderImg3 from "../../assets/images/sliderImg2.3.png";
import SliderImg4 from "../../assets/images/sliderImg2.4.png";
import SliderImg5 from "../../assets/images/sliderImg2.5.png";
import { useSelector } from "react-redux";

// import CropedsliderImg2 from "../../assets/images/CropedsliderImg2.1.png";

SwiperCore.use([EffectCoverflow]);

const ImageSlider = () => {
  const swiperRef = useRef(null);
  const { userInfo} = useSelector((state) => state.user);

  useEffect(() => {
    const autoSlider = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
        swiperRef.current.swiper.slideNext();
      }
    };
    const sliderInerval = setInterval(autoSlider, 2000);

    return () => clearInterval(sliderInerval);
  }, []);

  const sliderImageList = [
    SliderImg1,
    SliderImg2,
    SliderImg3,
    SliderImg4,
    SliderImg5,
  ];

  const handleMoveEnter = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };
  return (
    <>
      <Swiper
        ref={swiperRef}
        effect={"coverflow"}
        centeredSlides={true}
        slidesPerView={"auto"}
        spaceBetween={0}
        loop="true"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 300,
          modifier: 1.3,
        }}
        breakpoints={{
          1920: {
            spaceBetween: -690,
          },
          1820: {
            spaceBetween: -670,
          },
          1720: {
            spaceBetween: -650,
          },
          1620: {
            spaceBetween: -600,
          },
          1520: {
            spaceBetween: -520,
          },
          1420: {
            spaceBetween: -520,
          },
          1320: {
            spaceBetween: -450,
          },
          1220: {
            spaceBetween: -430,
          },
          1120: {
            spaceBetween: -410,
          },
          1020: {
            spaceBetween: -400,
          },
          1020: {
            spaceBetween: -50,
          },
          920: {
            spaceBetween: -20,
          },
          768: {
            spaceBetween: 0,
          },
        }}
        modules={[EffectCoverflow]}
        className="sliderImgs__Wrapper"
      >
        {sliderImageList.map((cur, id) => (
          <SwiperSlide
            className={`slider__ImageWrapper slider__Image_${id++}`}
            key={id}
            onMouseOver={handleMoveEnter}
          >
            <img className="slider__CardImage" src={cur} alt="sliderImgs" />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

const Hero = () => {
  const User = useSelector((state) => state.user.userInfo);

  const navigate = useNavigate();

  return (
    <div className="Hero__mainWrapper">
      <div className="Hero__mainContentWrapper">
        <div className="Hero__textMainWrapper">
          <div className="Hero__textWrapper">
            <motion.h1
              initial={{
                y: -100,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1.3,
                ease: "backOut",
              }}
              id="Hero__heading"
            >
              Discover the latest issue of{" "}
              <span className="upwealth__textColor">upwealth</span>{" "}
              <span className="magazine__textColor">magazine</span>
            </motion.h1>
            <motion.p
              initial={{
                y: -100,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1.3,
                ease: "backOut",
              }}
              className="small__informationPara"
            >
              Stay informed with our insightful articles and expert advice.
            </motion.p>

            <motion.div
              initial={{
                y: -50,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1.3,
                ease: "backOut",
                damping: 300,
              }}
              className="subscribeAndArrowBtn__Wrapper"
            >
            <Link to={'https://portal.kaltechconsultancyservices.com/i/RQ1QvSEHLyguGfm/DTHW1B8h3LTpcqJ'} className='subscribeNow__Btn'>Subsribe Now!
            <img src={subscribeHoverIcon} alt="subscribeHoverIcon" />
            </Link>

            {/* {!User?.status && (
                <button
                  onClick={() => navigate("/register")}
                  className="subscribeNow__Btn"
                >
                  Subscribe Now!
                  <img src={subscribeHoverIcon} alt="subscribeHoverIcon" />
                </button>
              )} */}

              <a href="#about" className="moveToNewSec__Btn">
                <img src={DownArrowIcon} alt="downArrowIcon" />
              </a>
            </motion.div>
          </div>
        </div>
        <div className="Hero__sliderMainWrapper">
          <motion.div
            initial={{
              y: 100,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1.3,
              ease: "backOut",
            }}
            className="sliderImgs__mainWrapper"
          >
            <ImageSlider />
          </motion.div>
          <motion.div
            initial={{
              y: -50,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1.3,
              ease: "backOut",
              damping: 300,
            }}
            className="subscribeAndArrowBtn__Wrapper2"
          >
            {!User?.status && (
              <button
                onClick={() => navigate("/register")}
                className="subscribeNow__Btn"
              >
                Subscribe Now!
                <img src={subscribeHoverIcon} alt="subscribeHoverIcon" />
              </button>
            )}
            <a href="#about" className="moveToNewSec__Btn">
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
