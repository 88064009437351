import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import { ContactUs } from "./component/contact/ContactUs";
import { Login } from "./component/Login/Login";
import { Register } from "./component/Register/Register";
// import  Navbar  from "./component/Navbar/Navbar";
import { Provider } from 'react-redux';
import Store from "./Redux/Store";
import Subscribe from "./component/Subscribe";


const router = createBrowserRouter([
  {
    path: "/",
    exact: true,
    element: <App />,
    errorElement:<div className="flex justify-center items-center h-screen bg-red-500">
      <h1 className="text-center ">
        404  Error! Page not found
      </h1>
    </div>
  },
  {
    path:'contact',
    element:<ContactUs />
  },
  {
    path:'login',
    element:<Login />
  },
  {
    path:'register',
    element:<Register />
  },
  {
    path:'subscribe',
    element:<Subscribe />
  }
])


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <React.StrictMode>
      {/* <Navbar /> */}
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);


