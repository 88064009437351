import React, { useEffect, useRef } from 'react';

const Subscribe = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://portal.kaltechconsultancyservices.com/frm/2qc6wv5DC84cQiUQz.js';
    script.async = true;

    // Append the script to the section
    if (sectionRef.current) {
      sectionRef.current.appendChild(script);
    }

    // Cleanup the script when the component unmounts
    return () => {
      if (sectionRef.current) {
        sectionRef.current.removeChild(script);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      style={{
        width: '100%',
        height: '100vh',
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* The content from the script will be injected here */}
    </section>
  );
};

export default Subscribe;
