import React from "react";
import { motion } from "framer-motion";
import "./FooterTwo.scss";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Logo2.svg";
import Logo2 from "../../assets/images/logo.png";
import Logo3 from "../../assets/images/logo3.png";
import LogoMob from '../../assets/images/UpWealthMobile.png'
import LogoTab from '../../assets/images/UpWealthTablet.png'
import DownArrowIcon from "../../assets/images/downArrowIcon.png";

export const FooterTwo = ({ secID }) => {
  return (
    <div className="Footer__bottomContentWrapper">
      <div className="Footer__bottomWrapper">
        <div className="Footer__bottomTopWrapper">
          <div className="Footer__linkWrapper">
            <div className="Footer__homeAndContactLinkWrapper">
              <Link className="Footer__links" to="/">
                Home
              </Link>
              <Link className="Footer__links" to="/contact">
                Contact Us
              </Link>
            </div>

            <div className="Footer__logoWrapper">
              <img src={Logo} alt="logo" />
            </div>

            <div className="Footer__kalAndLifeLinkWrapper">
              <Link className="Footer__links__kal" to="http://kstudeo.com/" target="_blank" rel="noopener noreferrer">
                KSTUDEO
              </Link>
              <Link className="Footer__links__Life" to="/">
                Insurance Life{" "}
                <span className="Footer__linkSpanCon">Magazine</span>
              </Link>
            </div>
          </div>
          <div className="Footer__socialMediaWrapper">
            <div className="Footer__socialMediaSeprater" />
            <div className="Footer__socialWrapper">
            <Link to={"https://www.facebook.com/people/UpWealth-Magazine/100089572389034/"} target="_blank">
              <div className="Footer__socialFacebookWrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                </svg>
              </div>
              </Link>

              <Link to={"https://www.instagram.com/upwealthmagazine/"} target="_blank">
              <div className="Footer__socialFacebookWrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </div>
              </Link>

              <Link to={"https://x.com/Upwealthmag"} target="_blank">
              <div className="Footer__socialFacebookWrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="Footer__bottomTopWrapperTab">
          <div className="Footer__leftMainWrapper">
            <div className="Footer__logoWrapper">
              <img src={Logo2} alt="logo" />
            </div>

            <div className="Footer__socialMediaWrapper">
              <div className="Footer__socialMediaSeprater" />
              <div className="Footer__socialWrapper">
              <Link to={"https://www.facebook.com/people/UpWealth-Magazine/100089572389034/"} target="_blank">
                <div className="Footer__socialFacebookWrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                  </svg>
                </div>
                </Link>

                <Link to={"https://www.instagram.com/upwealthmagazine/"} target="_blank">
                <div className="Footer__socialFacebookWrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg>
                </div>
                </Link>

                <Link to={"https://x.com/Upwealthmag"} target="_blank">
                <div className="Footer__socialFacebookWrapper">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                  </svg>
                </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="Footer__rightMainWrapper">
            <div className="Footer__homeAndContactLinkWrapper">
              <Link className="Footer__links" to="/">
                Home
              </Link>
              <Link className="Footer__links" to="/contact">
                Contact Us
              </Link>
            </div>

            <div className="Footer__kalAndLifeLinkWrapper">
            <Link className="Footer__links__kal" to="http://kstudeo.com/" target="_blank" rel="noopener noreferrer">
            KSTUDEO
          </Link>
              <Link className="Footer__links__Life" to="/">
                Insurance Life{" "}
                <span className="Footer__linkSpanCon">Magazine</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="Footer__bottomTopWrapperMob">
          <div className="Footer__logoWrapperMob">
            <img src={Logo2} alt="logo" />
          </div>

          <div className="Footer__LinksWrapperMob">
            <Link className="Footer__links" to="/">
              Home
            </Link>
            <Link className="Footer__links" to="/contact">
              Contact Us
            </Link>
            <Link className="Footer__links__kal" to="http://kstudeo.com/" target="_blank" rel="noopener noreferrer">
                KSTUDEO
              </Link>
            <Link className="Footer__links__Life" to="/">
              Insurance Life{" "}
              <span className="Footer__linkSpanCon">Magazine</span>
            </Link>
          </div>

          
          <div className="Footer__socialMediaWrapperMob">
          <Link to={"https://www.facebook.com/people/UpWealth-Magazine/100089572389034/"} target="_blank">
            <div className="Footer__socialFacebookWrapper">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
              </svg>
            </div>
            </Link>

            <Link to={"https://www.instagram.com/upwealthmagazine/"} target="_blank">
            <div className="Footer__socialFacebookWrapper">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </div>
            </Link>

            <Link to={"https://x.com/Upwealthmag"} target="_blank">
            <div className="Footer__socialFacebookWrapper">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
              </svg>
            </div>
            </Link>
          </div>
        </div>

        <div className="Footer__waterMarks">
          <p>© 2022 - 2024 | Alrights reserved by <Link to={"https://kaltechconsultancy.tech/"}  target="_blank" rel="noopener noreferrer" style={{color: "black", textDecoration: "none"}}>Kaltech Designs</Link> </p>
        </div>
      </div>

      <motion.a
        initial={{ scale: 1.5 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 0.3, ease: "backInOut" }}
        href={`#${secID}`}
        className="moveToNewSec__Btn"
        aria-description="move-to-the-next-section-button"
      >
        <img src={DownArrowIcon} alt="downArrowIcon" />
      </motion.a>
    </div>
  );
};
