import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import Logo from "../../assets/images/Logo2.svg";
import Logo2 from "../../assets/images/logo.png";
import MenuIcon from "../../assets/images/MenuIcon.png";
import { motion } from "framer-motion";
import CancelIcon from "../../assets/images/CancelIcon.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import { loginSuccess, logout } from "../../Redux/userSlice";
const API_URL = process.env.REACT_APP_SERVER_URL;

const Navbar = () => {
  const [screenPositionY, setScreenPosiionY] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const router = useNavigate();
  const { userInfo} = useSelector((state) => state.user);
  // const User = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      setScreenPosiionY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenPositionY]);

    // React Api Url from .env
    const apiUrl = process.env.REACT_APP_API_URL;

  // User session function
  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/logout`, {
        method: "POST",
        credentials: "include", // Ensure cookies are sent with the request
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Logout failed");
      }

      // Dispatch logout action to clear user info in Redux
      dispatch(logout());
      console.log(data.message); // Handle success message
    } catch (err) {
      console.error(err.message); // Handle error
    }
  }

  // const checkUserSession = async (token) => {
  //   const session = await axios.post(`${API_URL}/session`, {
  //     userToken: token,
  //   });

  //   if (session.data.status) {
  //     dispatch(loginSuccess(session?.data));
  //   }
  // };

  // useEffect(() => {
  //   const userToken = Cookies.get("usertoken");
  //   if (!userToken) return;

  //   checkUserSession(userToken);
  // }, []);

  return (
    <motion.nav
      initial={{
        y: -50,
        opacity: 0,
      }}
      whileInView={{
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: "backOut",
      }}
      className="Navbar__outerWrapper"
      style={{
        backgroundColor: screenPositionY >= 5 ? "#f0f0f3" : "transparent",
        boxShadow:
          screenPositionY >= 5 ? "1px 1px 10px rgba(0, 0, 0, 0.2)" : "none",
        transition: " .3s ease-in-out",
      }}
    >
      <div className="Navbar__mainWrapper">
        <div className="Navbar__LogoWrapper">
          <div className="Navbar__logo">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="Navbar__logoSmallScreen">
            <Link to="/">
              <img src={Logo2} alt="logo-ss" />
            </Link>
          </div>
        </div>
        <div className="Navbar__navLinksWrapper">
          <ul>
            {[
              { linkName: "Home", links: "/" },
              { linkName: "Contact Us", links: "/contact" },
            ].map((cur, id) => (
              <NavLink
                style={({ isActive }) => {
                  return {
                    boxShadow:
                      isActive &&
                      `inset 1px 1px 2px rgba(0, 0, 0, 0.25), inset -1px -1px 2px #ffffff,
            inset  -5px -5px 11.5px #ffffff, inset 5px 5px 8.5px rgba(0, 0, 0, 0.25)`,
                  };
                }}
                className="Navbar__navLinks"
                to={cur.links}
                key={id}
              >
                {cur.linkName}
              </NavLink>
            ))}
          </ul>
        </div>
        <Link to={'https://portal.kaltechconsultancyservices.com/site/login'} className="Navbar__LoginBtn">Login</Link>
        {/*
          
          {userInfo ? (
          <button className="Navbar__LoginBtn"
            onClick={handleLogout}
          >
            Log Out
          </button>
        ) : (
          <button onClick={() => router("/login")} className="Navbar__LoginBtn">
            Log In
          </button>
        )}

          */}
        
        <div onClick={() => setOpenMenu(true)} className="Navbar__MenuWrapper">
          <img src={MenuIcon} alt="menu-icon" />
        </div>
      </div>

      {openMenu && <MenuModel setOpenMenu={setOpenMenu} />}
    </motion.nav>
  );
};

export default Navbar;

const MenuModel = ({ setOpenMenu }) => {
  const router = useNavigate();
  const User = useSelector((state) => state?.user?.userInfo);
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -150 }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          y: -150,
        }}
        transition={{ duration: 0.5, ease: "backInOut" }}
        className="Navbar__menuModelOutterWrapper"
      >
        <div className="Navbar__menuModelMainWrapper">
          <div className="Navbar__menuModelTopWrapper">
            <div className="Navbar__menuModelTopCancelWrapper">
              <button onClick={() => setOpenMenu(false)}>
                <img src={CancelIcon} alt="cancel-icon" />
              </button>
            </div>
            <div className="Navbar__menuModelTopWrapperText">
              <h3>Menu</h3>
            </div>
          </div>
          <ul className="Navbar__menuModelUL">
            <Link
              to="/"
              onClick={() => setOpenMenu(false)}
              className="Navbar__menuModelLink"
            >
              Home
            </Link>
            <Link
              onClick={() => setOpenMenu(false)}
              className="Navbar__menuModelLink"
              to="/contact"
            >
              Contact us
            </Link>
          </ul>
          <div className="Navbar__menuModelLoginWrapper">
          <Link to={'https://portal.kaltechconsultancyservices.com/site/login'} className="Navbar__LoginBtn">Login</Link>
          {/* 
            
            {!User?.status ? (
              <button
                onClick={() => router("/login")}
                className="Navbar__LoginBtn"
              >
                Log In
              </button>
            ) : (
              <button
                onClick={() => {
                  Cookies.remove("usertoken");
                  window.location.reload();
                }}
                className="Navbar__LoginBtn"
              >
                Log Out
              </button>
            )}
            */}
            
          </div>
        </div>
      </motion.div>
    </>
  );
};
