import React, { useState, Suspense } from "react";
// import Navbar from "../Navbar/Navbar";

import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import "./Register.scss";

import LoginCardBg from "../../assets/images/LoginCardBg.png";
import Logo from "../../assets/images/logo.png";
import LoginEyeIcon from "../../assets/images/LoginEyeIcon.png";
import FaceBookIcon from "../../assets/images/facebookIcon.png";
import InstagramIcon from "../../assets/images/InstagramIcon.png";
import TwitterIcon from "../../assets/images/TwitterIcon.png";
import LinkedinIcon from "../../assets/images/LinkedInIcon.png";
import GoBackArrowIcon from "../../assets/images/downArrowIcon.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UseSignup from '../../utils/customHooks/UseSignup';


export const Register = () => {
  const navigate = useNavigate();
  const [hideShowPass, setHideShowPass] = useState({
    state: false,
    whichInput: "",
  });
  const handleHideAndShowPass = (e, inputName) => {
    setHideShowPass({
      state: !hideShowPass.state,
      whichInput: inputName,
    });
  };

  // BACKEND 
  
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setlastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setconfirmPassword] = useState("");

//  Form data state
const [formData, setFormData] = useState({
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
})

// set error state
const [Errors, setErrors] = useState("")

  // Function to validate form inputs
const validate = (data) => {
  const validationErrors = {};
  if (!data.firstName) validationErrors.firstName = 'First name is required';
  if (!data.lastName) validationErrors.lastName = 'Last name is required';
  if (!data.email) validationErrors.email = 'Email is required';
  if (!data.phone) validationErrors.phone = 'Email is required';
  if (!data.password) validationErrors.password = 'Password is required';
  if (!data.confirmPassword) validationErrors.confirmPassword = 'Confirm Password is required';
  return validationErrors;
};

// Function to connect to backend
const handleChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });
};

  // React Api Url from .env 
  const apiUrl = process.env.REACT_APP_API_URL;

const handleSubmit = async (e) => {
  e.preventDefault();
  const validationErrors = validate(formData); // Validate inputs
  if (Object.keys(validationErrors).length === 0) {
    try {
      const response = await fetch(`${apiUrl}/users/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', 
        body: JSON.stringify(formData),
      });
      
      const result = await response.json();
      if (response.ok) {
        // Handle success (e.g., redirect user, display success message)
        toast.success("You've successfully registered")
        setTimeout(()=>{
          navigate('/login')
        }, 2000);
        console.log('User signed up successfully:', result);
      } else {
        // Handle errors from the backend (e.g., user already exists)
        setErrors({ form: result.message });
      }
    } catch (error) {
      setErrors({ form: 'Failed to connect to server' });
    }
  } else {
    setErrors(validationErrors);
  }
};


  // const signupFormHandler = async (e) =>{
  //   e.preventDefault();
  //   const user = {
  //     firstName,
  //     lastName,
  //     email,
  //     phoneNumber,
  //     password,
  //     confirmPassword
  //   }
  //   const result = await UseSignup(user);
  //   toast[result.icon](`${result.msg}`);

  //   if(result.status){
  //     setFirstName("");
  //     setlastName("");
  //     setEmail("");
  //     setPhoneNumber("");
  //     setPassword("");
  //     setconfirmPassword("");
  //     setTimeout(()=>{
  //       navigate('/login');
  //     },2000);
  //   }
   
  // }

  return (
    <>
      {/* <Navbar /> */}

      <motion.button
        onClick={() => navigate("/")}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.6, ease: "backInOut" }}
        className="Register__goBackBTN"
      >
        <img src={GoBackArrowIcon} alt="go-back-arrow" />
        <span>Go Back</span>
      </motion.button>

      <Suspense fallback={"hello"}>
        <div style={{ overflow: "hidden" }} className="Register__outterWrapper">
          <motion.div
            initial={{
              opacity: 0,
              y: 200,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.6,
              ease: "backInOut",
            }}
            className="Register__mainFormWrapper"
          >
            <div className="Register__cardWrapper">
              <img
                className="Register__CardBg"
                src={LoginCardBg}
                alt="Login-card-img"
              />
              <Link to="/">
                <img className="Register__CardLogo" src={Logo} alt="logo"  />
              </Link>

              <div className="Register__CardTextWrapper">
                <h1>Welcome to Upwealth Magazine</h1>
              </div>
            </div>
            <div className="Register__FormWrapper">
              <h2 className="Register__FormTitle">Subscribe</h2>
           <form onSubmit={handleSubmit}>
           <div className="Register__FormInputsMainWrapper">
                <div className="Register__FormInputsWrapper">
                  <div>
                    <input 
                    type="text" 
                    name="firstName"
                    placeholder="First Name" 
                    value={formData.firstName} 
                    onChange={handleChange} 
                    required/>
                    {Errors.firstName && <span>{Errors.firstName}</span>}
                  </div>
                  <div>
                    <input 
                    type="text" 
                    name="lastName"
                    placeholder="Last Name" 
                    value={formData.lastName} 
                    onChange={handleChange} 
                    required/>
                    {Errors.lastName && <span>{Errors.lastName}</span>}
                  </div>
                </div>

                <div className="Register__FormInputsWrapper">
                  <div>
                    <input 
                    type="email" 
                    name="email"
                    placeholder="Email ID" 
                    value={formData.email} 
                    onChange={handleChange} 
                    required/>
                    {Errors.email && <span>{Errors.email}</span>}
                  </div>
                  <div>
                    <input 
                    type="number" 
                    name="phone"
                    placeholder="Phone number" 
                    value={formData.phone} 
                    onChange={handleChange} 
                    required/>
                    {Errors.phone && <span>{Errors.phone}</span>}
                  </div>
                </div>

                <div className="Register__FormInputsWrapper">
                  <div>
                    <input
                      type={
                        hideShowPass.state &&
                        hideShowPass.whichInput === "Password"
                          ? "text"
                          : "password"
                      }
                      placeholder="Password" 
                      name="password"
                      value={formData.password}
                      onChange={handleChange} 
                      required
                    />

                    <img
                      onClick={(e) => handleHideAndShowPass(e, "Password")}
                      className="Register__InputEyeIcon"
                      src={LoginEyeIcon}
                      alt="eye-icon" 
                    />

                  </div>
                  {Errors.password && <span>{Errors.password}</span>}
                  <div>
                    <input
                      type={
                        hideShowPass.state &&
                        hideShowPass.whichInput === "CPassword"
                          ? "text"
                          : "password"
                      }
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      value={formData.confirmPassword} 
                      required
                    />

                    <img
                      onClick={(e) => handleHideAndShowPass(e, "CPassword")}
                      className="Register__InputEyeIcon"
                      src={LoginEyeIcon}
                      alt="eye-icon"
                    />
                  </div>
                  {Errors.password && <span>{Errors.password}</span>}
                </div>
                {Errors.form && <p 
                  style={{
                    fontSize: '16px', 
                    color: 'red',     
                    marginTop: '15px',
                  }}
                  >{Errors.form}</p>}
                <button type="submit" className="Register__SubscribeBtn">Subscribe</button>
              </div>
           </form>
                <ToastContainer style={{ fontSize: '14px' }}/>
              <div className="Register__LoginOptions">
                <h4>
                  Already have a subscription?{" "}
                  <Link className="Register__loginLink" to="/login">
                    Login
                  </Link>
                </h4>

                <div className="Register__socialMediaOptions">
                  <h4>Or subscribe with</h4>
                  <div className="Register__SocialMediaMainWrapper">

                  <Link to={"https://www.facebook.com/people/UpWealth-Magazine/100089572389034/"} target="_blank">
                    <div className="Register__socialMediaIconWrapper">
                      {/* <img src={FaceBookIcon} alt="social-media-icon" /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                      </svg>
                    </div>
                    </Link>

                    <Link to={"https://www.instagram.com/upwealthmagazine/"} target="_blank">
                    <div className="Register__socialMediaIconWrapper">
                      {/* <img src={InstagramIcon} alt="social-media-icon" /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                      </svg>
                    </div>
                    </Link>

                    <Link to={"https://x.com/Upwealthmag"} target="_blank">
                    <div className="Register__socialMediaIconWrapper">
                      {/* <img src={TwitterIcon} alt="social-media-icon" /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                      </svg>
                    </div>
                    </Link>  


                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </Suspense>
    </>
  );
};
